import {ActionListType} from '@api/posMiddlewareCustomerLoyalty/ActionListType';

export enum AclKey {
  customerList = 'customerList',
  customerGet = 'customerGet',
  customerDelete = 'customerDelete',
  customerGenerateAndSendPin = 'customerGenerateAndSendPin',

  customerTransactionsCreate = 'customerTransactionsCreate',

  customerCardCreate = 'customerCardCreate',
  customerCardRemove = 'customerCardRemove',

  customerAttributeCreate = 'customerAttributeCreate',
  customerAttributeUpdate = 'customerAttributeUpdate',
  customerAttributeRemove = 'customerAttributeRemove',

  customerContactCreate = 'customerContactCreate',
  customerContactRemove = 'customerContactRemove',

  configurationGet = 'configurationGet',
  configurationUpdate = 'configurationUpdate',
}

export const AclMap: {[key in AclKey]: ActionListType} = {
  [AclKey.customerList]: 'api.v1.admin.customers.get',
  [AclKey.customerGet]: 'api.v1.admin.customers.detail.get',
  [AclKey.customerDelete]: 'api.v1.admin.customers.detail.remove',
  [AclKey.customerGenerateAndSendPin]: 'api.v1.admin.customers.generate.and.send.pin',


  [AclKey.customerTransactionsCreate]: 'api.v1.admin.customer.transactions.create',

  [AclKey.customerCardCreate]: 'api.v1.admin.customers.detail.card.create',
  [AclKey.customerCardRemove]: 'api.v1.admin.customers.card.remove',

  [AclKey.customerAttributeCreate]: 'api.v1.admin.customers.detail.attribute.create',
  [AclKey.customerAttributeUpdate]: 'api.v1.admin.customers.detail.attribute.update',
  [AclKey.customerAttributeRemove]: 'api.v1.admin.customers.detail.attribute.delete',

  [AclKey.customerContactCreate]: 'api.v1.admin.customers.detail.contact.create',
  [AclKey.customerContactRemove]: 'api.v1.admin.customers.detail.contact.remove',

  [AclKey.configurationGet]: 'api.v1.admin.loyalty.configuration.get',
  [AclKey.configurationUpdate]: 'api.v1.admin.loyalty.configuration.update',
};
