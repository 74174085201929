import {useMenu} from '@ui/helpers/menu';
import {PhIcons} from '@ui/helpers/phIcons';
import {computed} from 'vue';
import {useI18n} from 'vue-i18n';

export function useAppMenu () {
  const {sniffMenuState} = useMenu();
  const i18n = useI18n();

  const menu = computed(() => sniffMenuState([
    {
      label: i18n.t('layout.components.aside.menu.dashboard'),
      to: {name: 'dashboard'},
      icon: 'PhHouse' as PhIcons,
    },
    {
      label: i18n.t('layout.components.aside.menu.customers'),
      to: {name: 'customers'},
      icon: 'PhUser' as PhIcons,
      sectionRoutes: [
        'customers',
        'customer',
        'customer-transactions',
        'customer-cards',
        'customer-attributes',
        'customer-contacts',
      ],
    },
    {
      label: i18n.t('layout.components.aside.menu.configuration'),
      to: {name: 'configuration-update'},
      icon: 'PhGearSix' as PhIcons,
      sectionRoutes: [
        'configuration-update',
      ],
    },
  ]));

  return {
    menu,
  };
}
