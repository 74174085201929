import apiV1LoyaltyTranslationsGet
  from '@api/posMiddlewareCustomerLoyalty/action/apiV1LoyaltyTranslationsGet';
import {
  Store,
  createConfigureStore,
  createUseStore,
} from '@designeo/vue-helpers';
import {useCoreStore} from '@ui/modules/Core/store/CoreStore';

export interface ITranslationStore {
  translations?: Record<any, any>,
}

export const createInitState = () => ({
  translations: null,
});

export class TranslationStore extends Store<ITranslationStore> {
  constructor() {
    super(createInitState());
  }

  get coreStore() {
    return useCoreStore();
  }

  get translations() {
    return this.state.translations;
  }

  setTranslations(translations: ITranslationStore['translations']) {
    this.state.translations = translations;
  }

  async fetchTranslations(): Promise<Record<any, any>> {
    try {
      this.coreStore.setLoader(true);

      const response = await apiV1LoyaltyTranslationsGet();
      this.setTranslations(response);

      return response;
    } catch (error) {
      this.coreStore.alert(error);
      console.error;
    } finally {
      this.coreStore.setLoader(false);
    }
  }
}

const storeIdentifier = 'TranslationStore';

export const configureTranslationStore = createConfigureStore<typeof TranslationStore>(storeIdentifier);
export const useTranslationStore = createUseStore(TranslationStore, storeIdentifier);

