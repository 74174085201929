
export default {
  cs: {
    app: {
      appName: 'Loyalty',
    },
    layout: {
      components: {
        aside: {
          menu: {
            customers: 'Zákazníci',
            dashboard: 'Dashboard',
            configuration: 'Konfigurace',
          },
        },
      },
    },
  },
};
