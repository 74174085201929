import {App} from 'vue';
import translations from './translations';

/**
 * Defines what this module needs
 */
export type CoreRequiredContext = import('@ui/plugins/modules').ModuleSystem & {
  app: App<any>,
}

/**
 * Define what this module provides thru context
 */
export type CoreContext = CoreRequiredContext & {
}

export async function registerCustomerAccountTransactionModule(ctx: CoreRequiredContext) {
  await ctx.registerTranslations(translations);
}


