export default {
  cs: {
    configuration: {
      form: {
        update: {
          title: 'Konfigurace',
        },
      },
      fields: {
        configuration: 'Konfigurace',
      },
    },
  },
};
