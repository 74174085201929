import Error from '@api/posMiddlewareCustomerLoyalty/entity/Error';
import ArrayType from '@api/posMiddlewareCustomerLoyalty/types/ArrayType';
import BaseType from '@api/posMiddlewareCustomerLoyalty/types/BaseType';
import EntityType from '@api/posMiddlewareCustomerLoyalty/types/EntityType';
import StringType from '@api/posMiddlewareCustomerLoyalty/types/StringType';

export default {
  identifier: {
    type: new StringType(),
  },
  errors: {
    type: new ArrayType(new EntityType(Error)),
  },
  additionalProperties: {
    type: new BaseType(),
  },
};
