import {RegisterRoutesOptions} from '@ui/plugins/modules';

export default [
  {
    path: '/customers',
    name: 'customers',
    component: () => import('./Grid/GridPage.vue'),
    meta: {
      // TODO: fix acl
      // aclKeyForAccess: AclKey.customerList,
    },
  },
  {
    path: '/customers/:id',
    name: 'customer',
    component: () => import('./Detail/DetailPage.vue'),
    props: true,
    redirect: {name: 'customer-transactions'},
    children: [
      {
        path: 'transactions',
        name: 'customer-transactions',
        component: () => import('./Detail/tabs/TabTransactions.vue'),
      },
      {
        path: 'cards',
        name: 'customer-cards',
        component: () => import('./Detail/tabs/TabCards.vue'),
      },
      {
        path: 'attributes',
        name: 'customer-attributes',
        component: () => import('./Detail/tabs/TabAttributes.vue'),
      },
      {
        path: 'contacts',
        name: 'customer-contacts',
        component: () => import('./Detail/tabs/TabContacts.vue'),
      },
    ],
  },
] as RegisterRoutesOptions;
