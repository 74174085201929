export default {
  cs: {
    dashboard: {
      title: 'Zákaznický systém Posigneo',
      quickSearch: {
        title: 'Rychlé hledání',
        customerNumber: 'Číslo zákazníka',
        cardNumber: 'Zákaznická karta',
        receiptNumber: 'Číslo účtenky',
        firstNameAndLastName: 'Jméno a příjmení',
        phone: 'Telefon',
        email: 'E-mail',
        text: 'Text',
      },
      overview: {
        title: 'Přehled',
        customers: 'Žádný zákazník | {n} zákazník | {n} zákazníci | {n} zákazníků',
        customersActive: 'Žádný aktivní | {n} aktivní | {n} aktivní | {n} aktivních',
        customersLastWeek: '{n} za posl. týden',
      },
      form: {
        validations: {
          atLeastOneFieldIsRequired: 'Vyplňte alespoň jedno políčko',
        },
        noCustomerFound: 'Nebyl nalezen žádný zákazník',
      },
    },
  },
};
