import {useTranslationStore} from './store/TranslationStore';
import translations from './translations';

/**
 * Defines what this module needs
 */
export type RequiredContext = import('@ui/plugins/modules').ModuleSystem & {
  appId: any,
  preloadHooks: any[],
}

/**
 * Define what this module provides thru context
 */
export type Context = RequiredContext & {
}

export async function registerAppCoreModule(ctx: RequiredContext) {
  await ctx.registerTranslations(translations);
}

export async function resiterTranslationsOverrideModule(ctx: RequiredContext) {
  const configurationStore = useTranslationStore();

  const overrideTranslations = await configurationStore.fetchTranslations();

  await ctx.registerTranslations(overrideTranslations);
}
