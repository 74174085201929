
export default {
  cs: {
    customerCard: {
      grid: {
        title: 'Karty',
        actions: {
          remove: {
            confirm: 'Opravdu chcete smazat kartu {cardNumber}?',
          },
        },
      },
      form: {
        create: {
          title: 'Přidat kartu',
        },
        placeholders: {
          cardNumber: 'Zadejte čislo karty',
        },
      },
      fields: {
        cardNumber: 'Číslo karty',
        issuedDate: 'Vydáno',
        activeFrom: 'Platné od',
        activeTill: 'Platné do',
        isActive: 'Stav',
        isActiveEnum: {
          'true': 'Aktivní',
          'false': 'Neaktivní',
        },
      },
    },
  },
};
