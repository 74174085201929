import ObjectType from '@api/posMiddlewareCustomerLoyalty/types/ObjectType';
import StringType from '@api/posMiddlewareCustomerLoyalty/types/StringType';

export default {
  code: {
    type: new StringType(),
  },
  key: {
    type: new StringType(),
  },
  title: {
    type: new StringType(),
  },
  instructions: {
    type: new StringType(),
  },
  property: {
    type: new StringType(),
  },
  parameters: {
    type: new ObjectType(),
  },
};
