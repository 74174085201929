import ErrorResponse from '@api/posMiddlewareCustomerLoyalty/entity/ErrorResponse';
import BaseType from '@api/posMiddlewareCustomerLoyalty/types/BaseType';
import EntityType from '@api/posMiddlewareCustomerLoyalty/types/EntityType';
import URLQueryParamsType from '@api/posMiddlewareCustomerLoyalty/types/URLQueryParamsType';
import VoidType from '@api/posMiddlewareCustomerLoyalty/types/VoidType';
import axios from '../axios';

export default {
  name: 'api.v1.loyalty.translations.get',
  method: <'GET'>'GET',
  path: '/api/v1/admin/translations',
  inputType: new VoidType(),
  outputTypes: {
    200: new BaseType(),
  },
  errorTypes: {
    400: new EntityType(ErrorResponse),
    500: new EntityType(ErrorResponse),
  },
  paramsType: new URLQueryParamsType<{ }>(),
  axios,
};
