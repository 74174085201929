import {CustomerContactType} from '@api/posMiddlewareCustomerLoyalty/constants/customerContactType';

export default {
  cs: {
    customerContact: {
      grid: {
        title: 'Karty',
        actions: {
          delete: {title: 'Smazat', confirm: 'Opravdu chcete smazat tento kontakt?'},
        },
      },
      form: {
        create: {
          title: 'Přidat Kontakt',
        },
        placeholders: {
          type: 'Zadejte typ kontaktu',
          value: 'Zadejte kontakt',
          label: 'Zadejte popis',
        },
      },
      fields: {
        key: 'Kód',
        type: 'Typ kontaktu',
        typeEnum: {
          [CustomerContactType.phone]: 'Telefon',
        },
        value: 'Kontakt',
        label: 'Popis',
      },
    },
  },
};
