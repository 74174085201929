
export default {
  cs: {
    customerAttribute: {
      grid: {
        title: 'Karty',
        actions: {
          update: {title: 'Upravit'},
          delete: {title: 'Smazat', confirm: 'Opravdu chcete smazat atribut?'},
        },
      },
      form: {
        create: {
          title: 'Přidat Atribut',
        },
        update: {
          title: 'Upravit Atribut',
        },
        placeholders: {
          key: 'Zadejte klíč',
          value: 'Zadejte hodnotu',
        },
      },
      fields: {
        key: 'Kód',
        value: 'Hodnota',
      },
    },
  },
};
