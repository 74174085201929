import ErrorResponse from '@api/posMiddlewareCustomerLoyalty/entity/ErrorResponse';
import UserDtoIEnvelope from '@api/posMiddlewareCustomerLoyalty/entity/UserDtoIEnvelope';
import EntityType from '@api/posMiddlewareCustomerLoyalty/types/EntityType';
import URLQueryParamsType from '@api/posMiddlewareCustomerLoyalty/types/URLQueryParamsType';
import VoidType from '@api/posMiddlewareCustomerLoyalty/types/VoidType';
import axios from '../axios';

export default {
  name: 'api.v1.admin.identity.me',
  method: <'GET'>'GET',
  path: '/api/v1/admin/identity',
  inputType: new VoidType(),
  outputTypes: {
    200: new EntityType(UserDtoIEnvelope),
  },
  errorTypes: {
    400: new EntityType(ErrorResponse),
    500: new EntityType(ErrorResponse),
  },
  paramsType: new URLQueryParamsType<{ }>(),
  axios,
};
