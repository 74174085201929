import routes from './routes';
import translations from './translations';

/**
 * Defines what this module needs
 */
export type RequiredContext = import('@ui/plugins/modules').ModuleSystem & {
  appId: any,
  preloadHooks: any[],
}

/**
 * Define what this module provides thru context
 */
export type Context = RequiredContext & {
}

export async function registerCustomerModule(ctx: RequiredContext) {
  await ctx.registerTranslations(translations);

  await ctx.registerRoutes(routes);
}
