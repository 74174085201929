export default {
  cs: {
    customerPhone: {
      form: {
        create: {
          title: 'Upravit telefon',
        },
        placeholders: {
          phone: 'Zadejte telefon',
        },
      },
      fields: {
        phone: 'Telefon',
      },
    },
  },
};
