import {AclMap} from '@api/posMiddlewareCustomerLoyalty/acl/acl';
import apiV1AdminIdentityMe from '@api/posMiddlewareCustomerLoyalty/action/apiV1AdminIdentityMe';
import {createContext} from '@designeo/js-helpers';
import LayoutDefault from '@loyalty-ui/layouts/LayoutDefault.vue';
import {registerAppCoreModule, resiterTranslationsOverrideModule} from '@loyalty-ui/modules/AppCore';
import {registerCustomerAccountTransactionModule} from '@loyalty-ui/modules/CustomerAccountTransaction';
import {registerCustomerAttributeModule} from '@loyalty-ui/modules/CustomerAttribute';
import {registerCustomerCardModule} from '@loyalty-ui/modules/CustomerCard';
import {registerCustomerContactModule} from '@loyalty-ui/modules/CustomerContact';
import {registerCustomerPhoneModule} from '@loyalty-ui/modules/CustomerPhone';
import App from '@ui/layouts/App.vue';
import {registerAuthModule} from '@ui/modules/Auth';
import {registerCoreModule} from '@ui/modules/Core';
import {registerCrashDetection} from '@ui/plugins/crash';
import {registerVueI18n} from '@ui/plugins/i18n';
import {registerModuleSystem} from '@ui/plugins/modules';
import {registerRouter} from '@ui/plugins/router';
import {registerSentry} from '@ui/plugins/sentry';
import {registerSSRState} from '@ui/plugins/ssrState';
import {registerStartup} from '@ui/plugins/startup';
import {registerTestHelpers} from '@ui/plugins/testHelpers';
import {registerConfigurationModule} from './modules/Configuration';
import {registerCustomerModule} from './modules/Customer';
import {registerDashboardModule} from './modules/Dashboard';


interface createContextOptions {
  createApp: import('vue').CreateAppFunction<any>,
  ssrState: any,
}

export default async ({createApp, ssrState}: createContextOptions) => {
  const appId = {};

  const app = createApp(App, {
    appId,
  });

  const ctx = createContext({
    app,
    appId,
    isSplashScreen: false,
    preloadHooks: [],
    defaultLayout: LayoutDefault,
  })
    .register(registerCrashDetection)
    .register(registerStartup)
    .register(registerTestHelpers)
    .register(registerModuleSystem)
    .register(registerAuthModule({apiIdentifyMe: apiV1AdminIdentityMe, aclMap: AclMap}))
    .register(registerCoreModule())
    .register(registerAppCoreModule)
    .register(registerDashboardModule)
    .register(registerCustomerModule)
    .register(registerCustomerAccountTransactionModule)
    .register(registerCustomerAttributeModule)
    .register(registerCustomerCardModule)
    .register(registerCustomerContactModule)
    .register(registerCustomerPhoneModule)
    .register(registerConfigurationModule)
    .register(resiterTranslationsOverrideModule)
    .register(registerVueI18n)
    .register(registerRouter)
    .register(registerSSRState(ssrState))
    .register(registerSentry)
  ;

  const resolvedContext = await ctx.resolve();

  app.config.globalProperties.$context = resolvedContext;

  return resolvedContext;
};
