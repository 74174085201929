import {
  CustomerAccountTransactionType,
} from '@api/posMiddlewareCustomerLoyalty/constants/customerAccountTransactionType';

export default {
  cs: {
    customerAccountTransaction: {
      grid: {
        title: 'Transakce',
        filters: {
          type: 'Typ transakce',
          club: 'Klub',
          transactionDateFrom: 'Datum od',
          transactionDateTo: 'Datum do',
          receiptNumber: 'Číslo účtenky',
          shop: 'Prodejna',
        },
        advancedFilters: {
          title: 'Filtrovat transakce',
          placeholders: {
            receiptNumber: 'Zadejte číslo účtenky',
            club: 'Vyberte klub',
            shop: 'Vyberte prodejnu',
            type: 'Vyberte typ transakce',
          },
        },
      },
      form: {
        create: {
          title: 'Přidat transakci',
        },
        placeholders: {
          clubCode: 'Klub',
          points: 'Zadejte počet bodů',
          note: 'Zadejte krátký popis',
          longNote: 'Zadejte dlouhý popis',
        },
      },
      fields: {
        transactionDate: 'Datum a čas',
        place: 'Prodejna',
        receiptNumber: 'Čís. účtenky',
        clubCode: 'Klub',
        points: 'Body',
        type: 'Typ',
        pointsUsed: 'Čerp.',
        pointsValidTill: 'Datum expirace',
        expired: 'Exp.',
        typeEnum: {
          [CustomerAccountTransactionType.Collected]: 'Připsáno',
          [CustomerAccountTransactionType.Burned]: 'Čerpáno',
          [CustomerAccountTransactionType.Lost]: 'Ztraceno',
          [CustomerAccountTransactionType.Manual]: 'Ručně',
          [CustomerAccountTransactionType.Expiration]: 'Expirace',
          [CustomerAccountTransactionType.Correction]: 'Korekce',
          [CustomerAccountTransactionType.Transferred]: 'Převedeno',
        },
        note: 'Krátký popis',
        longNote: 'Dlouhý popis',
      },
      modalReceiptDetail: {
        title: 'Detail transakce {transactionDate}',
        createdBy: 'Transakci vytvořil',
        createdBySystem: 'Systém',
        longNote: 'Dlouhá poznámka',
        receipt: 'Zkrácená účtenka',
      },
    },
  },
};
