
import {RegisterRoutesOptions} from '@ui/plugins/modules';

export default [
  {
    path: '/configuration/update',
    name: 'configuration-update',
    component: () => import('./Form/UpdatePage.vue'),
    meta: {
      // TODO acl
      // aclKeyForAccess: AclKey.configurationGet,
    },
  },
] as RegisterRoutesOptions;
